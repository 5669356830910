import { useMutation } from '@apollo/client';
import { Trans, useTranslation } from '@getpopsure/i18n-react';
import classNames from 'classnames';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { alertBanner } from 'shared/reactiveVariables';

import { DELETE_REFERRAL_CAMPAIGN } from '../../graphql/mutations';
import { GET_REFERRAL_CAMPAIGNS } from '../../graphql/queries';
import { ReferralCampaign } from '../../types';

interface DeleteConfirmationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  referralCampaign: ReferralCampaign;
}

const DeleteConfirmationModal = ({
  open,
  setOpen,
  referralCampaign,
}: DeleteConfirmationModalProps) => {
  const { t } = useTranslation();
  const [deleteReferralCampaign, { loading }] = useMutation(
    DELETE_REFERRAL_CAMPAIGN,
    {
      refetchQueries: [GET_REFERRAL_CAMPAIGNS],
      onCompleted: () => {
        setOpen(false);
        alertBanner({
          type: 'SUCCESS',
          message: t(
            'referralCampaigns.deleteReferralCampaign.alert.success',
            'Referral campaign deleted.'
          ),
        });
      },
      onError: () => {
        alertBanner({
          type: 'WARNING',
          message: t(
            'referralCampaigns.deleteReferralCampaign.alert.error',
            'Something went wrong. Please try again.'
          ),
        });
      },
    }
  );

  const handleSubmit = async () => {
    deleteReferralCampaign({
      variables: { id: referralCampaign.id },
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t(
        'referralCampaigns.deleteReferralCampaign.modal.title',
        'Delete campaign'
      )}
      loading={loading}
      textLoading={t(
        'referralCampaigns.deleteReferralCampaign.modal.textLoading',
        'Deleting campaign...'
      )}
      hideActions
    >
      <div>
        <div className={classNames('text-gray-600', 'text-sm')}>
          <Trans
            key="referralCampaign.deleteCampaign.delete.confirm"
            referralCampaign={referralCampaign?.title}
          >
            Do you really want to delete this campaign? "
            {{ referralCampaign: referralCampaign?.title }}"
            <p className={classNames('pt-1')}>
              This action <b>cannot be undone.</b>
            </p>
          </Trans>
        </div>
        <div className={classNames('flex', 'justify-end', 'mt-[24px]')}>
          <Button
            type="button"
            buttonType="secondary"
            onClick={() => setOpen(false)}
          >
            {t('admin.modal.cancel.action', 'Cancel')}
          </Button>
          <Button
            loading={loading}
            type="submit"
            buttonType="primary"
            className={classNames('ml-2', 'min-w-[140px]')}
            onClick={handleSubmit}
          >
            {t('referralCampaign.deleteCampaign.delete.action', 'Delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
