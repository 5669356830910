import { useMutation } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Modal from 'components/Modal';
import { alertBanner } from 'shared/reactiveVariables';

import { UPDATE_REFERRAL_CAMPAIGN } from '../../graphql/mutations';
import { GET_REFERRAL_CAMPAIGNS } from '../../graphql/queries';
import { ReferralCampaign } from '../../types';
import ReferralCampaignForm from '../ReferralCampaignForm';

interface UpdateReferralCampaignModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  referralCampaign: ReferralCampaign;
}

const UpdateReferralCampaignModal = ({
  open,
  setOpen,
  referralCampaign,
}: UpdateReferralCampaignModalProps) => {
  const { t } = useTranslation();
  const [updateReferralCampaign, { loading, error }] = useMutation(
    UPDATE_REFERRAL_CAMPAIGN,
    {
      refetchQueries: [GET_REFERRAL_CAMPAIGNS],
      onCompleted: () => {
        setOpen(false);
        alertBanner({
          type: 'SUCCESS',
          message: t(
            'referralCampaigns.updateReferralCampaign.alert.success',
            'Campaign updated.'
          ),
        });
      },
      onError: () => {
        alertBanner({
          type: 'WARNING',
          message: t(
            'referralCampaigns.updateReferralCampaign.alert.error',
            'Something went wrong. Please try again.'
          ),
        });
      },
    }
  );

  const handleSubmit = async (input: ReferralCampaign) => {
    updateReferralCampaign({
      variables: {
        id: referralCampaign.id,
        input,
      },
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t(
        'referralCampaigns.updateReferralCampaign.title',
        'Update campaign'
      )}
      hideActions
    >
      <ReferralCampaignForm
        setOpen={setOpen}
        onSubmit={handleSubmit}
        loading={loading}
        error={error}
        formData={referralCampaign}
      />
    </Modal>
  );
};

export default UpdateReferralCampaignModal;
