import { useMutation } from '@apollo/client';
import { useTranslation } from '@getpopsure/i18n-react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { useState } from 'react';
import { alertBanner } from 'shared/reactiveVariables';

import { CREATE_REFERRAL_CODE } from '../../graphql/mutations';
import { GET_REFERRAL_CODES } from '../../graphql/queries';
import { ReferralCodeInput } from '../../types';
import ReferralCodeForm from '../ReferralCodeForm';

const CreateReferralCodeModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [createReferralCode, { error, loading, reset }] = useMutation(
    CREATE_REFERRAL_CODE,
    {
      refetchQueries: [GET_REFERRAL_CODES],
      onCompleted: () => {
        openCreateReferralCodeForm(false);
        alertBanner({
          type: 'SUCCESS',
          message: t(
            'referralCodes.createReferralCode.alert.success',
            'New referral code created'
          ),
        });
      },
      onError: () => {
        alertBanner({
          type: 'WARNING',
          message: t(
            'referralCodes.createReferralCode.alert.error',
            'Something went wrong. Please try again.'
          ),
        });
      },
    }
  );

  const handleButtonClick = () => openCreateReferralCodeForm(true);

  const openCreateReferralCodeForm = (open: boolean) => {
    setOpen(open);
    if (open) {
      reset();
    }
  };

  const handleSubmit = (input: ReferralCodeInput) => {
    createReferralCode({
      variables: input,
    });
  };

  return (
    <>
      <Button onClick={handleButtonClick} buttonType="primary">
        {t('referralCodes.createReferralCode.button', 'New referral code')}
      </Button>
      {open && (
        <Modal
          open={open}
          setOpen={setOpen}
          title={t(
            'referralCodes.createReferralCode.modal.title',
            'Create new referral code'
          )}
          loading={loading}
          textLoading={t(
            'referralCodes.createReferralCode.modal.textLoading',
            'Creating...'
          )}
          hideActions
          scrollable
        >
          <ReferralCodeForm
            setOpen={setOpen}
            onSubmit={handleSubmit}
            loading={loading}
            error={error}
          />
        </Modal>
      )}
    </>
  );
};

export default CreateReferralCodeModal;
